import { observer } from 'mobx-react-lite';
import React, { FormEvent, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Form, Grid, Header, Message, Segment } from 'semantic-ui-react';

import { ILogin } from '../models/login';

interface IProps {
  title: string;
  description: string;
  idPlaceholder: string;
  passwordPlaceholder: string;
  invalidCredentialsMessage: string;
  userlockedMessage: string;
  formValues: ILogin;
  setFormValues: (values: ILogin) => void;
  handleAuthenticate: (values: ILogin) => Promise<void>;
}

const LoginComponent: React.FC<IProps> = ({
  title,
  description,
  idPlaceholder,
  passwordPlaceholder,
  invalidCredentialsMessage,
  userlockedMessage,
  formValues,
  setFormValues,
  handleAuthenticate,
}) => {
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = () => {
    setSubmitting(true);
    handleAuthenticate(formValues)
      .catch((error) => {
        const { data } = error.response;
        if (data.message === 'Invalid request body' || data.message === 'Invalid credentials') {
          data.errorCode
            ? toast.error(`${invalidCredentialsMessage}(${data.errorCode})`)
            : toast.error(invalidCredentialsMessage);
        } else if (data.message === 'User locked') {
          toast.error(userlockedMessage);
        }
      })
      .finally(() => setSubmitting(false));
  };

  const handleInputChange = (event: FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setFormValues({ ...formValues, [name]: value });
  };

  return (
    <Grid textAlign="center">
      <Grid.Column textAlign="center" style={{ width: '100%', maxWidth: 450, minWidth: 350 }}>
        <Segment>
          {title && (
            <Header as="h3" textAlign="center">
              <div dangerouslySetInnerHTML={{ __html: title }}></div>
            </Header>
          )}
          {description && (
            <Message size="tiny">
              <div dangerouslySetInnerHTML={{ __html: description }}></div>
            </Message>
          )}
          <Form size="large" onSubmit={handleSubmit}>
            <Form.Input
              fluid
              icon="user"
              iconPosition="left"
              placeholder={idPlaceholder}
              name="id"
              value={formValues.id}
              onChange={handleInputChange}
            />
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder={passwordPlaceholder}
              type="password"
              name="password"
              value={formValues.password}
              onChange={handleInputChange}
            />
            <Button fluid size="large" type="submit" disabled={submitting} loading={submitting}>
              Login
            </Button>
          </Form>
        </Segment>
      </Grid.Column>
    </Grid>
  );
};

export default observer(LoginComponent);
