import React, { Fragment } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Label } from 'semantic-ui-react';

import AuthComplete from '../../features/complete/AuthComplete';
import DBAuth from '../../features/db-auth/DBAuth';
import DBAuthCompleteRoute from '../../features/db-auth/DBAuthCompleteRoute';
import GFCAuth from '../../features/gfc-auth/GFCAuth';
import GFCAuthCompleteRoute from '../../features/gfc-auth/GFCAuthCompleteRoute';
import LunaSeaAuth from '../../features/lunasea-auth/LunaSeaAuth';
import LunaSeaAuthCompleteRoute from '../../features/lunasea-auth/LunaSeaAuthCompleteRoute';
import MFCAuth from '../../features/mfc-auth/MFCAuth';
import MFCAuthCompleteRoute from '../../features/mfc-auth/MFCAuthCompleteRoute';
import MOOOSAuth from '../../features/mooos-auth/MOOOSAuth';
import MOOOSAuthCompleteRoute from '../../features/mooos-auth/MOOOSAuthCompleteRoute';
import PASCHAuth from '../../features/pasch/PASCHAuth';
import PASCHAuthCompleteRoute from '../../features/pasch/PASCHAuthCompleteRoute';
import RFCAuth from '../../features/rfc-auth/RFCAuth';
import RFCAuthCompleteRoute from '../../features/rfc-auth/RFCAuthCompleteRoute';
import SKIYAKIAuth from '../../features/skiyaki-auth/SKIYAKIAuth';
import SKIYAKIAuthCompleteRoute from '../../features/skiyaki-auth/SKIYAKIAuthCompleteRoute';
import SMCMSAuth from '../../features/smcms/SMCMSAuth';
import SMCMSAuthCompleteRoute from '../../features/smcms/SMCMSAuthCompleteRoute';
import SmTownAuth from '../../features/smtown-auth/SmTownAuth';
import SmTownAuthCompleteRoute from '../../features/smtown-auth/SmTownAuthCompleteRoute';
import WeverseAuth from '../../features/weverse-auth/WeverseAuth';
import WeverseAuthCompleteRoute from '../../features/weverse-auth/WeverseAuthCompleteRoute';
import WeverseAuthCompleteRouteV2 from '../../features/weverse-authV2/WeverseAuthCompleteRouteV2';
import WeverseAuthV2 from '../../features/weverse-authV2/WeverseAuthV2';
import Error from './Error';
import NotFound from './NotFound';

const App: React.FC = () => {
  return (
    <Fragment>
      <ToastContainer position="top-center" />
      {process.env.REACT_APP_ENV && (
        <Label size="big" attached="top left" color="green" content={process.env.REACT_APP_ENV} />
      )}
      <Switch>
        <Route exact path="/team-ayu" component={MOOOSAuth} />
        <MOOOSAuthCompleteRoute exact path="/team-ayu/complete" component={AuthComplete} />
        <Route exact path="/lunasea" component={LunaSeaAuth} />
        <LunaSeaAuthCompleteRoute exact path="/lunasea/complete" component={AuthComplete} />
        <Route exact path="/weverse_auth" component={WeverseAuthV2} />
        <Route exact path="/weverse_auth/:session_id" component={WeverseAuthV2} />
        <WeverseAuthCompleteRouteV2 exact path="/weverse_auth/:session_id/complete" component={AuthComplete} />
        <Route exact path="/weverse" component={WeverseAuth} />
        <Route exact path="/weverse/:session_id" component={WeverseAuth} />
        <WeverseAuthCompleteRoute exact path="/weverse/:session_id/complete" component={AuthComplete} />
        <Route exact path="/smtown" component={SmTownAuth} />
        <SmTownAuthCompleteRoute exact path="/smtown/complete" component={AuthComplete} />
        <Route exact path="/pasch" component={PASCHAuth} />
        <PASCHAuthCompleteRoute exact path="/pasch/complete" component={AuthComplete} />
        <Route exact path="/smcms" component={SMCMSAuth} />
        <SMCMSAuthCompleteRoute exact path="/smcms/complete" component={AuthComplete} />
        <Route exact path="/skiyaki" component={SKIYAKIAuth} />
        <SKIYAKIAuthCompleteRoute exact path="/skiyaki/complete" component={AuthComplete} />
        <Route exact path="/db" component={DBAuth} />
        <DBAuthCompleteRoute exact path="/db/complete" component={AuthComplete} />
        <Route exact path="/rfc" component={RFCAuth} />
        <RFCAuthCompleteRoute exact path="/rfc/complete" component={AuthComplete} />
        <Route exact path="/gfc" component={GFCAuth} />
        <GFCAuthCompleteRoute exact path="/gfc/complete" component={AuthComplete} />
        <Route exact path="/mfc" component={MFCAuth} />
        <Route exact path="/mfc/:session_id" component={MFCAuth} />
        <MFCAuthCompleteRoute exact path="/mfc/:session_id/complete" component={AuthComplete} />
        <Route exact path="/error" component={Error} />
        <Route component={NotFound} />
      </Switch>
    </Fragment>
  );
};

export default withRouter(App);
