import { action, observable } from 'mobx';

import { history } from '../..';
import { Members } from '../api/agent';
import { ILogin } from '../models/login';
import { RootStore } from './rootStore';

export default class MemberStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable sessionId = '';
  @observable ticket = '';
  @observable isLogInDbAuth = false;
  @observable isLogInRfcAuth = false;
  @observable isLogInGfcAuth = false;
  @observable isLogInMfcAuth = false;
  @observable isLogInSkiyakiAuth = false;
  @observable isLogInSmcmsAuth = false;
  @observable isLogInSmTownAuth = false;
  @observable isLoginPaschAuth = false;
  @observable isLogInWeverseAuth = false;
  @observable isLogInLunaSeaAuth = false;
  @observable isLogInMooosAuth = false;

  @action loginDbAuth = async (values: ILogin) => {
    try {
      const login = await Members.loginDbAuth(values);
      this.sessionId = values.session_id;
      this.ticket = login.ticket;
      this.isLogInDbAuth = true;
      history.push('/db/complete');
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  @action loginRfcAuth = async (values: ILogin) => {
    try {
      const login = await Members.loginRfcAuth(values);
      this.sessionId = values.session_id;
      this.ticket = login.ticket;
      this.isLogInRfcAuth = true;
      history.push('/rfc/complete');
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  @action loginGfcAuth = async (values: ILogin) => {
    try {
      const login = await Members.loginGfcAuth(values);
      this.sessionId = values.session_id;
      this.ticket = login.ticket;
      this.isLogInGfcAuth = true;
      history.push('/gfc/complete');
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  @action loginMfcAuth = async (values: ILogin) => {
    try {
      const login = await Members.loginMfcAuth(values);
      this.sessionId = values.session_id;
      this.ticket = login.ticket;
      this.isLogInMfcAuth = true;
      history.push(`/mfc/${values.session_id}/complete`);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  @action getMfcAuth = async (client_id: string, auth_params: string, sessionId: string) => {
    try {
      return await Members.getMfcAuth(client_id, auth_params, sessionId);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  @action loginSkiyakiAuth = async (values: ILogin) => {
    try {
      const login = await Members.loginSkiyakiAuth(values);
      this.sessionId = values.session_id;
      this.ticket = login.ticket;
      this.isLogInSkiyakiAuth = true;
      history.push('/skiyaki/complete');
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  @action getSkiyakiAuth = async (client_id: string, auth_params: string) => {
    try {
      return await Members.getSkiyakiAuth(client_id, auth_params);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  @action loginSmcmsAuth = async (values: ILogin) => {
    try {
      const login = await Members.loginSmcmsAuth(values);
      this.sessionId = values.session_id;
      this.ticket = login.ticket;
      this.isLogInSmcmsAuth = true;
      history.push('/smcms/complete');
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  @action loginSmTownAuth = async (values: ILogin) => {
    try {
      const login = await Members.loginSmTownAuth(values);
      this.sessionId = values.session_id;
      this.ticket = login.ticket;
      this.isLogInSmTownAuth = true;
      history.push('/smtown/complete');
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  @action loginPaschAuth = async (values: ILogin) => {
    try {
      const login = await Members.loginPaschAuth(values);
      this.sessionId = values.session_id;
      this.ticket = login.ticket;
      this.isLoginPaschAuth = true;
      history.push('/pasch/complete');
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  @action loginWeverseAuth = async (values: ILogin) => {
    try {
      const login = await Members.loginWeverseAuth(values);
      this.sessionId = values.session_id;
      this.ticket = login.ticket;
      this.isLogInWeverseAuth = true;
      history.push(`/weverse/${values.session_id}/complete`);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  @action getWeverseAuth = async (sessionId: string) => {
    try {
      return await Members.getWeverseAuth(sessionId);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  @action loginWeverseAuthV2 = async (values: ILogin) => {
    try {
      const login = await Members.loginWeverseAuthV2(values);
      this.sessionId = values.session_id;
      this.ticket = login.ticket;
      this.isLogInWeverseAuth = true;
      history.push(`/weverse_auth/${values.session_id}/complete`);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  @action getWeverseAuthV2 = async (sessionId: string, clientId: string, authParams: string) => {
    try {
      return await Members.getWeverseAuthV2(sessionId, clientId, authParams);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  @action loginLunaSeaAuth = async (values: ILogin) => {
    try {
      const login = await Members.loginLunaSeaAuth(values);
      this.sessionId = values.session_id;
      this.ticket = login.ticket;
      this.isLogInLunaSeaAuth = true;
      history.push('/lunasea/complete');
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  @action loginMooosAuth = async (values: ILogin) => {
    try {
      const login = await Members.loginMooosAuth(values);
      this.sessionId = values.session_id;
      this.ticket = login.ticket;
      this.isLogInMooosAuth = true;
      history.push('/team-ayu/complete');
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  @action getMooosAuth = async (sessionId: string, clientId: string, authParams: string) => {
    try {
      return await Members.getMooosAuth(sessionId, clientId, authParams);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
}
