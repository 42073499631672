import { observer } from 'mobx-react-lite';
import queryString from 'query-string';
import React, { useContext, useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { history } from '../..';
import LoadingComponent from '../../app/layout/LoadingComponent';
import { LoginValues } from '../../app/models/login';
import { RootStoreContext } from '../../app/stores/rootStore';

const WeverseAuth: React.FC<
  RouteComponentProps<{
    session_id: string;
  }>
> = ({ location, match }) => {
  const rootStore = useContext(RootStoreContext);
  const { setSession, getSession } = rootStore.sessionStore;
  const { getWeverseAuthV2, loginWeverseAuthV2 } = rootStore.memberStore;

  const { session_id } = match.params;
  const { client_id, auth_params, callback_url, access_token } = queryString.parse(location.search);

  const formRef = useRef<any | null>(null);
  const inputClientIdRef = useRef<any | null>(null);
  const inputRedirectUriRef = useRef<any | null>(null);

  useEffect(() => {
    if (access_token) {
      // Weverseからのコールバック呼び出し
      (async () => {
        try {
          // セッション取得
          const session = await getSession(session_id);
          // 認証先のパラメータを設定
          const values = new LoginValues();
          values.session_id = session_id;
          values.client_id = session.client_id;
          values.auth_params = session.auth_params;
          values.id = access_token as string;
          // 認証
          await loginWeverseAuthV2(values);
        } catch (error) {
          history.push('/error');
        }
      })();
    } else {
      // 初回呼び出し
      (async () => {
        try {
          // セッション保持
          const session = await setSession({
            session_id: '',
            client_id: client_id as string,
            auth_params: auth_params as string,
            callback_url: callback_url as string,
          });
          // Weverse認証パラメータ作成API呼び出し
          const weverse = await getWeverseAuthV2(session.session_id, session.client_id, session.auth_params);
          // Weverse認証にRedirect!!
          inputClientIdRef.current.name = 'client_id';
          inputClientIdRef.current.value = weverse.client_id;
          inputRedirectUriRef.current.name = 'redirect_uri';
          inputRedirectUriRef.current.value = weverse.redirect_uri;
          formRef.current.action = weverse.target_url;
          formRef.current.method = 'GET';
          formRef.current.submit();
        } catch (error) {
          history.push('/error');
        }
      })();
    }
  }, [
    auth_params,
    callback_url,
    client_id,
    access_token,
    session_id,
    getSession,
    getWeverseAuthV2,
    loginWeverseAuthV2,
    setSession,
  ]);

  return (
    <form ref={formRef}>
      <input type="hidden" ref={inputClientIdRef} />
      <input type="hidden" ref={inputRedirectUriRef} />
      <LoadingComponent content="Redirecting..." />
    </form>
  );
};

export default observer(WeverseAuth);
