import { configure } from 'mobx';
import { createContext } from 'react';

import FanclubStore from './fanclubStore';
import MemberStore from './memberStore';
import ResultStore from './resultStore';
import SessionStore from './sessionStore';

configure({ enforceActions: 'always' });

export class RootStore {
  sessionStore: SessionStore;
  fanclubStore: FanclubStore;
  memberStore: MemberStore;
  resultStore: ResultStore;

  constructor() {
    this.sessionStore = new SessionStore(this);
    this.fanclubStore = new FanclubStore(this);
    this.memberStore = new MemberStore(this);
    this.resultStore = new ResultStore(this);
  }
}

export const RootStoreContext = createContext(new RootStore());
