export interface ILogin {
  session_id: string;
  client_id: string;
  auth_params: string;
  id: string;
  password: string;
  ticket: string;
}

export class LoginValues implements ILogin {
  session_id: string = '';
  client_id: string = '';
  auth_params: string = '';
  id: string = '';
  password: string = '';
  ticket: string = '';

  constructor(init?: ILogin) {
    Object.assign(this, init);
  }
}
