import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';

import { history } from '../..';
import LoadingComponent from '../../app/layout/LoadingComponent';
import { RootStoreContext } from '../../app/stores/rootStore';

const AuthComplete: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { sessionId, ticket } = rootStore.memberStore;
  const { getSession } = rootStore.sessionStore;
  const { getResult } = rootStore.resultStore;

  useEffect(() => {
    try {
      (async () => {
        // セッション取得
        const session = await getSession(sessionId);
        // 認証結果レスポンス作成API呼び出し
        const { client_id, callback_url, auth_params } = session;
        const result = await getResult(client_id, auth_params, ticket);
        // Callback!!
        if (callback_url.includes('?')) {
          window.location.replace(`${callback_url}&result=${result}`);
        } else {
          window.location.replace(`${callback_url}?result=${result}`);
        }
      })();
    } catch (error) {
      history.push('/error');
    }
  }, [sessionId, ticket, getResult, getSession]);

  return <LoadingComponent content="Redirecting..." />;
};

export default observer(AuthComplete);
