import { observer } from 'mobx-react-lite';
import queryString from 'query-string';
import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Container } from 'semantic-ui-react';

import { history } from '../..';
import LoadingComponent from '../../app/layout/LoadingComponent';
import LoginComponent from '../../app/layout/LoginComponent';
import { LoginValues } from '../../app/models/login';
import { RootStoreContext } from '../../app/stores/rootStore';

const PASCHAuth: React.FC<RouteComponentProps> = ({ location }) => {
  const rootStore = useContext(RootStoreContext);
  const { setSession } = rootStore.sessionStore;
  const { loginPaschAuth } = rootStore.memberStore;

  const { client_id, callback_url, auth_params } = queryString.parse(location.search);

  const [appLoaded, setAppLoaded] = useState(false);
  const [sessionId, setSessionId] = useState('');

  const [formValues, setFormValues] = useState(new LoginValues());

  useEffect(() => {
    try {
      (async () => {
        // セッション保持
        const session = await setSession({
          session_id: '',
          client_id: client_id as string,
          auth_params: auth_params as string,
          callback_url: callback_url as string,
        });
        setSessionId(session.session_id);
        setAppLoaded(true);
      })();
    } catch (error) {
      history.push('/error');
    }
  }, [auth_params, callback_url, client_id, setSession]);

  if (!appLoaded) return <LoadingComponent content="Loading..." />;

  return (
    <Container>
      <LoginComponent
        title="<img src='https://fanauthgw-static-content.s3.ap-northeast-1.amazonaws.com/XG_alphaz.jpg' alt='logo' />"
        description=""
        idPlaceholder="EMAIL ADDRESS"
        passwordPlaceholder="PASSWORD"
        invalidCredentialsMessage="Login failed. Please check again to see if your ID and password are entered correctly?"
        userlockedMessage="アカウントがロックされました。時間を空けてから再度お試しください。"
        formValues={formValues}
        setFormValues={setFormValues}
        handleAuthenticate={async () => {
          // 認証先のパラメータを設定
          const { ...values } = formValues;
          values.session_id = sessionId;
          values.client_id = client_id as string;
          values.auth_params = auth_params as string;
          // 認証
          await loginPaschAuth(values);
        }}
      />
    </Container>
  );
};

export default observer(PASCHAuth);
