import { action } from 'mobx';

import { Fanclubs } from '../api/agent';
import { RootStore } from './rootStore';

export default class FanclubStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action getFanclub = async (client_id: string, auth_params: string) => {
    try {
      return await Fanclubs.getFanclub(client_id, auth_params);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
}
