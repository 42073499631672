import React from 'react';
import { Container, Grid, Header, Icon } from 'semantic-ui-react';

const NotFound = () => {
  return (
    <Container>
      <Grid textAlign="center">
        <Grid.Column textAlign="left" style={{ width: '100%', maxWidth: 450 }}>
          <Header icon>
            <Icon name="search" />
            PAGE NOT FOUND
          </Header>
        </Grid.Column>
      </Grid>
    </Container>
  );
};

export default NotFound;
