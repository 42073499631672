import { action } from 'mobx';

import { Sessions } from '../api/agent';
import { ISession } from '../models/session';
import { RootStore } from './rootStore';

export default class SessionStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action setSession = async (values: ISession) => {
    try {
      return await Sessions.setSession(values);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  @action getSession = async (session_id: string) => {
    try {
      return await Sessions.getSession(session_id);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
}
