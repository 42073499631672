import { observer } from 'mobx-react-lite';
import queryString from 'query-string';
import React, { useContext, useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { history } from '../..';
import LoadingComponent from '../../app/layout/LoadingComponent';
import { LoginValues } from '../../app/models/login';
import { RootStoreContext } from '../../app/stores/rootStore';

const MFCAuth: React.FC<
  RouteComponentProps<{
    session_id: string;
  }>
> = ({ location, match }) => {
  const rootStore = useContext(RootStoreContext);
  const { setSession, getSession } = rootStore.sessionStore;
  const { getMfcAuth, loginMfcAuth } = rootStore.memberStore;

  const { session_id } = match.params;
  const { client_id, auth_params, callback_url, enc_str } = queryString.parse(location.search);

  const formRef = useRef<any | null>(null);
  const inputRef = useRef<any | null>(null);

  useEffect(() => {
    if (enc_str) {
      // MFCからのコールバック呼び出し
      (async () => {
        try {
          // セッション取得
          const session = await getSession(session_id);
          // 認証先のパラメータを設定
          const values = new LoginValues();
          values.session_id = session_id;
          values.client_id = session.client_id;
          values.id = enc_str as string;
          // 認証
          await loginMfcAuth(values);
        } catch (error) {
          history.push('/error');
        }
      })();
    } else {
      // 初回呼び出し
      (async () => {
        try {
          // セッション保持
          const session = await setSession({
            session_id: '',
            client_id: client_id as string,
            auth_params: auth_params as string,
            callback_url: callback_url as string,
          });
          // MFC認証パラメータ作成API呼び出し
          const mfc = await getMfcAuth(client_id as string, auth_params as string, session.session_id);
          // MFC認証画面にRedirect!!
          inputRef.current.name = 'enc_str';
          inputRef.current.value = mfc.enc_str;
          formRef.current.action = mfc.target_url;
          formRef.current.method = 'POST';
          formRef.current.submit();
        } catch (error) {
          history.push('/error');
        }
      })();
    }
  }, [auth_params, callback_url, client_id, enc_str, session_id, getMfcAuth, getSession, loginMfcAuth, setSession]);

  return (
    <form ref={formRef}>
      <input type="hidden" ref={inputRef} />
      <LoadingComponent content="Redirecting..." />
    </form>
  );
};

export default observer(MFCAuth);
