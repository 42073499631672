import { action } from 'mobx';

import { Results } from '../api/agent';
import { RootStore } from './rootStore';

export default class ResultStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action getResult = async (client_id: string, auth_params: string, ticket: string) => {
    try {
      return await Results.getResult(client_id, auth_params, ticket);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
}
