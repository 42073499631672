import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Route, RouteComponentProps, RouteProps } from 'react-router-dom';

import NotFound from '../../app/layout/NotFound';
import { RootStoreContext } from '../../app/stores/rootStore';

interface IProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>>;
}

const GFCAuthCompleteRoute: React.FC<IProps> = ({ component: Component, ...rest }) => {
  const rootStore = useContext(RootStoreContext);
  const { isLogInGfcAuth } = rootStore.memberStore;
  return <Route {...rest} render={(props) => (isLogInGfcAuth ? <Component {...props} /> : <NotFound />)} />;
};

export default observer(GFCAuthCompleteRoute);
