import axios, { AxiosResponse } from 'axios';
import axiosRetry from 'axios-retry';
import { toast } from 'react-toastify';

import { IFanclub } from '../models/fanclub';
import { ILogin } from '../models/login';
import { IMfc } from '../models/mfc';
import { IMooos } from '../models/mooos';
import { ISession } from '../models/session';
import { ISkiyaki } from '../models/skiyaki';
import { IWeverse } from '../models/weverse';

axiosRetry(axios, {
  retries: 5,
  retryCondition: (error: any) => {
    return axiosRetry.isNetworkOrIdempotentRequestError(error) || error.status === 429;
  },
  retryDelay: (retryCount: number) => {
    return retryCount * 1000;
  },
});

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.response.use(undefined, (error) => {
  if (error.message === 'Network Error' && !error.response) {
    toast.error('Network Error!');
  }
  const { status } = error.response;
  if (status === 500) {
    toast.error('System Error!');
  }
  throw error;
});

const responseBody = (response: AxiosResponse) => response.data;

// const sleep = (ms: number) => (response: AxiosResponse) =>
//   new Promise<AxiosResponse>((resolve) => setTimeout(() => resolve(response), ms));

const requests = {
  get: (url: string) => axios.get(url).then(responseBody),
  post: (url: string, body: {}) => axios.post(url, body).then(responseBody),
  put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
  del: (url: string) => axios.delete(url).then(responseBody),
  patch: (url: string, body: {}) => axios.patch(url, body).then(responseBody),
};

export const Sessions = {
  setSession: (values: ISession): Promise<ISession> => requests.post(`session`, values),
  getSession: async (session_id: string): Promise<ISession> => {
    const params = new URLSearchParams();
    params.append('session_id', session_id);
    const response = await axios.get('/session', { params: params });
    return responseBody(response);
  },
};

export const Fanclubs = {
  getFanclub: async (client_id: string, auth_params: string): Promise<IFanclub> => {
    const params = new URLSearchParams();
    params.append('client_id', client_id);
    params.append('auth_params', auth_params);
    const response = await axios.get('/fanclubs', { params: params });
    return responseBody(response);
  },
};

export const Members = {
  loginDbAuth: (values: ILogin): Promise<ILogin> => requests.post(`login/db`, values),
  loginRfcAuth: (values: ILogin): Promise<ILogin> => requests.post(`login/rfc`, values),
  loginGfcAuth: (values: ILogin): Promise<ILogin> => requests.post(`login/gfc`, values),
  loginMfcAuth: (values: ILogin): Promise<ILogin> => requests.post(`login/mfc`, values),
  getMfcAuth: async (client_id: string, auth_params: string, sessionId: string): Promise<IMfc> => {
    const params = new URLSearchParams();
    params.append('client_id', client_id);
    params.append('auth_params', auth_params);
    params.append('session_id', sessionId);
    const response = await axios.get('/mfc', { params: params });
    return responseBody(response);
  },
  loginSkiyakiAuth: (values: ILogin): Promise<ILogin> => requests.post(`login/skiyaki`, values),
  getSkiyakiAuth: async (client_id: string, auth_params: string): Promise<ISkiyaki> => {
    const params = new URLSearchParams();
    params.append('client_id', client_id);
    params.append('auth_params', auth_params);
    const response = await axios.get('/skiyaki', { params: params });
    return responseBody(response);
  },
  loginSmcmsAuth: (values: ILogin): Promise<ILogin> => requests.post(`login/smcms`, values),
  loginSmTownAuth: (values: ILogin): Promise<ILogin> => requests.post(`login/smtown`, values),
  loginPaschAuth: (values: ILogin): Promise<ILogin> => requests.post(`login/pasch`, values),
  loginWeverseAuth: (values: ILogin): Promise<ILogin> => requests.post(`login/weverse`, values),
  getWeverseAuth: async (sessionId: string): Promise<IWeverse> => {
    const params = new URLSearchParams();
    params.append('session_id', sessionId);
    const response = await axios.get('/weverse', { params: params });
    return responseBody(response);
  },
  loginWeverseAuthV2: (values: ILogin): Promise<ILogin> => requests.post(`login/weverse/v2`, values),
  getWeverseAuthV2: async (session_id: string, client_id: string, auth_params: string): Promise<IWeverse> => {
    const params = new URLSearchParams();
    params.append('session_id', session_id);
    params.append('client_id', client_id);
    params.append('auth_params', auth_params);
    const response = await axios.get('/weverse/v2', { params: params });
    return responseBody(response);
  },
  loginLunaSeaAuth: (values: ILogin): Promise<ILogin> => requests.post(`login/lunasea`, values),
  loginMooosAuth: (values: ILogin): Promise<ILogin> => requests.post(`login/mooos`, values),
  getMooosAuth: async (session_id: string, client_id: string, auth_params: string): Promise<IMooos> => {
    const params = new URLSearchParams();
    params.append('session_id', session_id);
    params.append('client_id', client_id);
    params.append('auth_params', auth_params);
    const response = await axios.get('/mooos', { params: params });
    return responseBody(response);
  },
};

export const Results = {
  getResult: async (client_id: string, auth_params: string, ticket: string): Promise<string> => {
    const params = new URLSearchParams();
    params.append('client_id', client_id);
    params.append('auth_params', auth_params);
    params.append('ticket', ticket);
    const response = await axios.get('/result', { params: params });
    return responseBody(response);
  },
};
