import { observer } from 'mobx-react-lite';
import queryString from 'query-string';
import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Container } from 'semantic-ui-react';

import { history } from '../..';
import LoadingComponent from '../../app/layout/LoadingComponent';
import LoginComponent from '../../app/layout/LoginComponent';
import { IFanclub } from '../../app/models/fanclub';
import { LoginValues } from '../../app/models/login';
import { RootStoreContext } from '../../app/stores/rootStore';

const DBAuth: React.FC<RouteComponentProps> = ({ location }) => {
  const rootStore = useContext(RootStoreContext);
  const { setSession } = rootStore.sessionStore;
  const { getFanclub } = rootStore.fanclubStore;
  const { loginDbAuth } = rootStore.memberStore;

  const { client_id, auth_params, callback_url } = queryString.parse(location.search);

  const [appLoaded, setAppLoaded] = useState(false);
  const [sessionId, setSessionId] = useState('');
  const [fanclub, setFanclub] = useState<IFanclub | null>(null);
  const [formValues, setFormValues] = useState(new LoginValues());

  useEffect(() => {
    try {
      (async () => {
        // セッション保持
        const session = await setSession({
          session_id: '',
          client_id: client_id as string,
          auth_params: auth_params as string,
          callback_url: callback_url as string,
        });
        setSessionId(session.session_id);

        // ファンクラブマスタ取得
        const fanclub = await getFanclub(client_id as string, auth_params as string);
        setFanclub(fanclub);
        setAppLoaded(true);
      })();
    } catch (error) {
      history.push('/error');
    }
  }, [client_id, callback_url, auth_params, setSession, getFanclub, setAppLoaded]);

  if (!appLoaded) return <LoadingComponent content="Loading..." />;

  return (
    <Container>
      <LoginComponent
        title={fanclub ? fanclub.title : 'ファンクラブタイトル'}
        description={fanclub ? fanclub.description : 'ファンクラブ説明'}
        idPlaceholder="会員番号"
        passwordPlaceholder="パスワード"
        invalidCredentialsMessage="会員番号またはパスワードが正しくありません。"
        userlockedMessage="アカウントがロックされました。時間を空けてから再度お試しください。"
        formValues={formValues}
        setFormValues={setFormValues}
        handleAuthenticate={async () => {
          // 認証先のパラメータを設定
          const { ...values } = formValues;
          values.session_id = sessionId;
          values.client_id = client_id as string;
          values.auth_params = auth_params as string;
          // 認証
          await loginDbAuth(values);
        }}
      />
    </Container>
  );
};

export default observer(DBAuth);
